import React from 'react';
import '../../assets/css/style.css';
import finxprivacypolicy from './finxprivacypolicy'
import finxdisclaimer from './finxdisclaimer'
import finxcookiespolicy from './finxcookiespolicy'
import Typography from '@material-ui/core/Typography';
import FAQPDF from "../../assets/pdf/ExpertMFD_FAQs.pdf";
import Intro from "../../assets/pdf/Instructions_and_User_Journey.pdf";
import TC from "../../assets/pdf/T-C_ExpertMFD.pdf";
import POLICY from "../../assets/pdf/Policies_ExpertMFD.pdf";
// import '../../components/amfi/footer/Footer.css';
function Copyright() {
    return (
        <Typography variant="white" color="white" align="center">
            {' © '}
            {' '}
            {new Date().getFullYear()}
            {' All Right Reserved'}
            {'.'}
        </Typography>
    );
}
function finxfooter() {
    return (
        <div>
            <footer style={{ paddingTop: '10px' , paddingBottom: '0px',marginBottom: '0px',backgroundColor: '#0d6bc1'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <p className="text-center"><a href="#" target="_blank"style={{ color: 'white' , fontSize: '13px' }}> www.ExpertMFD.com</a></p>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <p className="text-center" style={{ color: 'white' , fontSize: '13px' }}> <Copyright />  </p>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <p className="text-center">
                                <a href={TC} target="_blank" style={{ color: 'white' , fontSize: '13px' }}>Terms and Conditions |</a>
                                <a href={POLICY} target="_blank" style={{ color: 'white' , fontSize: '13px' }}>Privacy Policy | </a>
                                <a href={FAQPDF} target="_blank" style={{ color: 'white' , fontSize: '13px' }}>FAQs | </a>
                                 <a href="https://finxpert.org/grievance-redressal/" target="_blank" >Grievance Redressal </a>
                                {/* <a href={Intro} target="_blank" >indroduction</a> */}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <p style={{ color: 'white' , fontSize: '11px' }}>
                        ExpertMFD Platform is accessible on chrome (version 55 and above), Firefox (version 50 and above) and Safari (version MacOS: Yosemite 8 and above, iPad: iPad Mini 2019 and above and iPhone: iPhone  6s and above)
                        </p>
                    </div>
                    </div>
                </div>
            </footer>
            <noscript>Not seeing a <a href="https://www.scrolltotop.com/">Scroll to Top Button</a>? Go to our FAQ page for more info.</noscript>
        </div>
    );
}

export default finxfooter;
